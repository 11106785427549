<!-- @format -->

<template>
	<div>
		<div class="buttonTitle">Collect Payment</div>
		<div v-if="transactionFormState.showButtons">
			<b-button
				class="is-primary is-rounded margin-top-15 margin-left-15"
				@click="getVariables('collect bank')"
				style="width: 300px"
			>
				Bank collect
			</b-button>
			<span class="explanation">(Customer pays our invoice via bank)</span>
			<br />
			<b-button
				class="is-primary is-rounded margin-top-15 margin-left-15"
				@click="getVariables('collect bank in advance')"
				style="width: 300px"
			>
				Bank collect in advance
			</b-button>
			<span class="explanation"
				>(Customer pays our invoice via bank in advance)</span
			>
			<br />
			<b-button
				class="is-primary is-rounded margin-top-15 margin-left-15"
				style="width: 300px"
				@click="getVariables('collect bank reminder fee')"
			>
				Bank collect reminder fee
			</b-button>
			<span class="explanation"
				>(Customer pays our reminder fee via bank)
			</span>
			<br />
			<b-button
				class="is-primary is-rounded margin-top-15 margin-left-15"
				style="width: 300px"
				@click="getVariables('get payback via collect bank')"
				>Get Payback via bank
			</b-button>
			<span class="explanation"
				>(Supplier is making a refund to us via bank)
			</span>
			<br />
			<b-button
				class="is-success is-rounded margin-top-15 margin-left-15"
				@click="getVariables('collect cash')"
				style="width: 300px"
			>
				Cash collect
			</b-button>
			<span class="explanation">(Customer pays our invoice in cash)</span>
			<br />

			<b-button
				class="is-success is-rounded margin-top-15 margin-left-15"
				style="width: 300px"
				@click="getVariables('collect cash in advance')"
			>
				Cash collect in advance
			</b-button>
			<span class="explanation"
				>(Customer pays our invoice in cash in advance)
			</span>
			<br />

			<b-button
				class="is-success is-rounded margin-top-15 margin-left-15"
				style="width: 300px"
				@click="getVariables('collect cash reminder fee')"
			>
				Cash collect reminder fee
			</b-button>
			<span class="explanation">(Customer pays our reminder fee in cash) </span>
			<br />

			<b-button
				class="is-success is-rounded margin-top-15 margin-left-15"
				style="width: 300px"
				@click="getVariables('get payback collect cash')"
				>Get Payback Cash
			</b-button>
			<span class="explanation">(Supplier is making a cash refund to us) </span>
			<br />
			<div class="buttonTitle">Make Payment</div>
			<b-button
				class="is-primary is-rounded margin-top-15 margin-left-15"
				style="width: 300px"
				@click="getVariables('pay bank')"
			>
				Make bank payment
			</b-button>
			<span class="explanation">(We pay supplier's invoice via bank)</span>
			<br />
			<b-button
				class="is-primary is-rounded margin-top-15 margin-left-15"
				style="width: 300px"
				@click="getVariables('pay bank in advance')"
			>
				Make bank payment in advance
			</b-button>
			<span class="explanation"
				>(We pay supplier's invoice via bank in advance)
			</span>
			<br />
			<b-button
				class="is-primary is-rounded margin-top-15 margin-left-15"
				style="width: 300px"
				@click="getVariables('make payback via bank')"
				>Make Payback via Bank
			</b-button>
			<span class="explanation"
				>(We are making a refund to customer via bank)
			</span>
			<br />
			<b-button
				class="is-success is-rounded margin-top-15 margin-left-15"
				style="width: 300px"
				@click="getVariables('pay cash')"
			>
				Make cash payment
			</b-button>
			<span class="explanation">(We pay supplier's invoice in cash)</span>
			<br />

			<b-button
				class="is-success is-rounded margin-top-15 margin-left-15"
				style="width: 300px"
				@click="getVariables('pay cash in advance')"
			>
				Make cash payment in advance
			</b-button>
			<span class="explanation"
				>(We pay supplier's invoice in cash in advance)
			</span>
			<br />

			<b-button
				class="is-success is-rounded margin-top-15 margin-left-15"
				style="width: 300px"
				@click="getVariables('make payback cash')"
				>Make Payback Cash
			</b-button>
			<span class="explanation"
				>(We are making a cash refund to customer)
			</span>
			<div class="buttonTitle">Set off Records</div>
			<b-button
				class="is-info is-rounded margin-top-15 margin-left-15"
				style="width: 300px"
				@click="getVariables('set off credit invoice')"
				>Set off credit invoice
			</b-button>
			<span class="explanation"
				>(We credit the customer against the "Credit Invoice")
			</span>
			<br />
			<b-button
				class="is-info is-rounded margin-top-15 margin-left-15"
				style="width: 300px"
				@click="getVariables('set off advanced payment')"
				>Set off advanced payment
			</b-button>
			<span class="explanation"
				>(We credit the customer against the "Advanced Payment")
			</span>
			<div class="buttonTitle">
				Free Entry
				<span class="explanation"
					>(We can create manually payment record)
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import useTransactionAddEditForm from '@/_srcv2/pages/transactions/add-transaction/scripts/useTransactionAddEditForm.js'
import useTransactionAddEditFunctions from '@/_srcv2/pages/transactions/add-transaction/scripts/useAddEditTransactionFunctions'
export default {
	name: 'SelectTransactionProcess',
	setup() {
		const { transactionFormState } = useTransactionAddEditForm()
		const { getVariables } = useTransactionAddEditFunctions()
		return {
			transactionFormState,
			getVariables,
		}
	},
}
</script>

<style scoped>
.margin-top-15 {
	margin-top: 15px;
}
.margin-left-15 {
	margin-left: 15px;
}
.buttonTitle {
	margin-top: 10px;
	font-weight: bolder;
	font-size: x-large;
	color: darkblue;
}
.explanation {
	display: inline-block;
	margin-top: 20px;
	margin-left: 10px;
	font-weight: bolder;
	color: #9b1c1c;
}
</style>
